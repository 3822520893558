<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Products
      </v-card-title>
      <v-spacer></v-spacer>
      <v-autocomplete
        v-model="filter.category_id"
        :items="categories"
        item-value="id"
        item-text="name"
        clearable
        clear-icon="fas fa-times"
        class="mr-2"
        outlined
        multiple
        dense
        single-line
        hide-details
        label="Categories"
        @change="itemSearch()"
        @click:clear="itemSearch()"
      ></v-autocomplete>
      <v-autocomplete
        v-model="filter.brand_id"
        :items="brands"
        item-value="id"
        item-text="name"
        clearable
        clear-icon="fas fa-times"
        class="mr-2"
        outlined
        multiple
        dense
        single-line
        hide-details
        label="Brands"
        @change="itemSearch()"
        @click:clear="itemSearch()"
      ></v-autocomplete>
      <v-autocomplete
        v-model="filter.supplier_id"
        :items="suppliers"
        item-value="id"
        item-text="name"
        clearable
        clear-icon="fas fa-times"
        class="mr-2"
        outlined
        multiple
        dense
        single-line
        hide-details
        label="Suppliers"
        @change="itemSearch()"
        @click:clear="itemSearch()"
      ></v-autocomplete>
      <v-autocomplete
        v-model="filter.color_id"
        :items="colors"
        item-value="id"
        item-text="name"
        clearable
        clear-icon="fas fa-times"
        class="mr-2"
        outlined
        multiple
        dense
        single-line
        hide-details
        label="Colors"
        @change="itemSearch()"
        @click:clear="itemSearch()"
      ></v-autocomplete>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Search..."
        append-icon="fas fa-search"
        clearable
        clear-icon="fas fa-times"
        single-line
        hide-details
        @keyup="itemSearch()"
        @click:clear="itemSearch()"
      ></v-text-field>
      <v-btn
        class="ml-4"
        depressed
        color="secondary"
        @click="showItemDialog"
        :disabled="!user.can('product.create')"
      >
    <v-icon
      small
      left
    >fas fa-folder-plus</v-icon>
    New
    </v-btn>
    </v-row>

    <v-data-table
      v-model="products"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50, 100, 250, 500] }"
      show-select
      :loading="isSearching"
      @click:row="gotoItem"
    >
      <template v-slot:footer.prepend>
        <v-btn
          v-if="products.length"
          icon
          @click="odooDialogRef = true"
        >
          <v-icon>fas fa-sync</v-icon>
        </v-btn>
        <v-btn
          v-if="products.length"
          icon
          @click="productsDeleteDialog = true"
        >
          <v-icon>fas fa-trash</v-icon>
        </v-btn>
        <v-btn
          v-if="products.length"
          icon
          @click="productsCategoryChangeDialog = true"
        >
          <v-icon>fas fa-edit</v-icon>
        </v-btn>
        <span
          v-if="products.length"
          class="ml-5"
        >
          {{ products.length }} selected
        </span>
        <v-btn
          v-if="products.length"
          icon
          x-small
          @click="clearProducts()"
        ><v-icon color="grey">fas fa-times</v-icon></v-btn>

      </template>
    
    </v-data-table>

    <product-dialog
      v-model="dialog"
      :attributes="model"
      :errors="errors"
      :categories="categories"
      :brands="brands"
      :suppliers="suppliers"
      save-button-text="Create"
      @save="saveItem()"
    ></product-dialog>

    <odoo-product-dialog
      v-model="odooDialogRef"
      :products="products"
    ></odoo-product-dialog>

    <products-delete-dialog
      v-model="productsDeleteDialog"
      :products="products"
      @done="refreshItems()"
    ></products-delete-dialog>

    <products-category-change-dialog
      v-model="productsCategoryChangeDialog"
      :products="products"
      :categories="categories"
      @done="refreshItems()"
    ></products-category-change-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from 'vue'
import ProductDialog from '@/components/ProductDialog.vue'
import OdooProductDialog from '@/components/OdooProductDialog.vue'
import ProductsDeleteDialog from '@/components/ProductsDeleteDialog.vue'
import ProductsCategoryChangeDialog from '@/components/ProductsCategoryChangeDialog.vue'
import { useRouter } from 'vue-router/composables'

export default {
  components: {
    ProductDialog,
    OdooProductDialog,
    ProductsDeleteDialog,
    ProductsCategoryChangeDialog,
  },
  setup() {
    const api = inject('api')
    const loader = inject('loader')
    const router = useRouter()


    const itemCollection = api.collection('products', {
      params: {
        expand: 'category,brand',
      },
      filter: {
        query: 'query:unique_identifier,unique_name',
        category_id: 'in',
        brand_id: 'in',
        supplier_id: 'in',
        color_id: 'in:primary_color_id,secondary_color_id,tertiary_color_id,quaternary_color_id',
      },
      store: true,
    })

    const { items, filter, totalCount, options, isSearching } = itemCollection

    const headers = [
      { text: 'Code', value: 'unique_identifier', },
      { text: 'Name', value: 'unique_name' },
      { text: 'Category', value: 'category.name' },
      { text: 'Brand', value: 'brand.name' },
    ]

    const { items: brands } = api.collection('brands', { params: { sort: 'name' }}).all()
    const { items: categories } = api.collection('categories', { params: { sort: 'name' }}).all()
    const { items: suppliers } = api.collection('suppliers', { params: { sort: 'name' }}).all()
    const { items: colors } = api.collection('colors', { params: { sort: 'name' }}).all()

    const dialog = ref(false)

    const itemModel = api.model('products/:id', {
      params: {
      },
    })
    const { attributes: model, errors, isBusy } = itemModel

    loader.bindRef(isBusy)

    function showItemDialog() {
      itemModel.clear()
      dialog.value = true
    }

    function gotoItem(item) {
      router.push({
        name: 'product',
        params: { id: item.id }
      })
    }

    function refreshItems() {
      itemCollection.search()
      products.value = []
    }

    function saveItem() {
      itemModel.save().then(() => {
        itemCollection.search()
        router.push({
          name: 'product',
          params: { id: itemModel.getAttribute('id') }
        })
        dialog.value = false
      })
    }

    const odooDialogRef = ref(false)
    const productsDeleteDialog = ref(false)
    const productsCategoryChangeDialog = ref(false)
    const products = ref([])

    function clearProducts() {
      products.value = []
    }

    const itemSearch = itemCollection.debounceSearch(300)

    return {
      user: api.user,
      headers,
      items,
      filter,
      itemSearch,
      totalCount,
      options,
      isSearching,
      brands,
      categories,
      suppliers,
      colors,
      showItemDialog,
      gotoItem,
      saveItem,
      dialog,
      model,
      errors,

      odooDialogRef,
      productsDeleteDialog,
      productsCategoryChangeDialog,
      refreshItems,

      products,
      clearProducts,
    }

  },
}
</script>


<style scoped>

:deep(.v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover) {
  cursor: pointer;
}

</style>
